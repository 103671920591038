import { create } from "zustand";

const useCartStore = create((set) => ({
    cart: [],
    addToCart: (item) =>
        set((state) => {
            if (state.cart.length === 0) {
                return { cart: [{ ...item }] };
            }

            const existingItemIndex = state.cart.findIndex(
                (cartItem) =>
                    cartItem.id === item.id &&
                    cartItem.size === item.size &&
                    cartItem.color === item.color
            );

            if (existingItemIndex !== -1) {
                const updatedCart = state.cart.map((cartItem, index) =>
                    index === existingItemIndex
                        ? { ...cartItem, qtn: cartItem.qtn + item.qtn }
                        : cartItem
                );
                return { cart: updatedCart };
            } else {
                return { cart: [...state.cart, item] };
            }
        }),
    removeFromCart: (item) =>
        set((state) => ({
            cart: state.cart.filter(
                (cartItem) =>
                    cartItem.id !== item.id ||
                    cartItem.size !== item.size ||
                    cartItem.color !== item.color
            ),
        })),
    increaseQtn: (item) =>
        set((state) => {
            const updatedCart = state.cart.map((cartItem) =>
                cartItem.id === item.id &&
                cartItem.size === item.size &&
                cartItem.color === item.color
                    ? { ...cartItem, qtn: cartItem.qtn + 1 }
                    : cartItem
            );
            return { cart: updatedCart };
        }),
    decreaseQtn: (item) =>
        set((state) => {
            const updatedCart = state.cart.map((cartItem) =>
                cartItem.id === item.id &&
                cartItem.size === item.size &&
                cartItem.color === item.color
                    ? { ...cartItem, qtn: Math.max(cartItem.qtn - 1, 1) } // Ensuring qtn doesn't go below 1
                    : cartItem
            );
            return { cart: updatedCart };
        }),
}));

export default useCartStore;
