import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import CategoriesMenuItem from "./CategoriesMenuItem";
import { handleGetLanguage } from "../utils/languageStorage";
import { useLocation } from "react-router-dom";

export default function FilterCategories() {
    const [isVisible, setIsVisible] = useState(false);

    const language = handleGetLanguage();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const categorieQuery = queryParams.get("categorie");

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const fetchCategories = async () => {
        const response = await axiosInstance.get(`/categories`);
        if (response.status !== 200) {
            throw new Error("Something went wrong");
        }
        return response.data;
    };

    const { data: categoriesData } = useQuery({
        queryKey: ["categories"],
        queryFn: () => fetchCategories(),
    });

    let pathname = location.pathname;
    const updateQuery = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);

        if (pathname.endsWith("/")) {
            pathname = pathname.slice(0, -1);
        }

        return `${pathname}?${searchParams.toString()}`;
    };

    return (
        <div>
            <div className="font-medium flex items-center justify-between">
                <p>{language === "en" ? "Categories" : "კატეგორიები"}</p>
                <button onClick={toggleVisibility} className="">
                    {isVisible ? "▲" : "▼"}
                </button>
            </div>

            {isVisible && (
                <ul className="list-none">
                    {categoriesData?.map((category, i) => (
                        <CategoriesMenuItem
                            key={i}
                            category={category}
                            language={language}
                            updateQuery={updateQuery}
                            categoriesQuery={categorieQuery}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}
