import axios from "axios";
import { handleGetAuthCookie } from "./token";
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DATABASE_API_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = handleGetAuthCookie();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
