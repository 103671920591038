import React, { useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import Tagline from "../../../components/tagline";
import Navbar from "../../../components/navbar";
import ProductDetail from "../../../components/product-detail";
import ArrivalItem from "../../../components/arrival-item";
import Footer from "../../../components/footer";
import ScrollToTop from "../../../components/scroll-to-top";
import axiosInstance from "../../../utils/axiosInstance";
import { handleGetLanguage } from "../../../utils/languageStorage";
import ImageModal from "../../../components/ImageModal";
import { IoIosArrowBack, IoMdResize } from "react-icons/io";

const fetchData = async (id) => {
    const response = await axiosInstance.get(`/products/${id}`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function ProductDetailThree() {
    const language = handleGetLanguage();
    const translations = {
        en: {
            loading: "Loading...",
            Onlybrands: "Onlybrands",
            store: "Store",
            error: "Something went wrong",
        },
        ka: {
            loading: "იტვირთება...",
            Onlybrands: "მხოლოდ ბრენდები",
            store: "მაღაზია",
            error: "რამე არასწორედაა",
        },
    };
    const t = translations[language] || translations.en;
    const [selectedImage, setSelectedImage] = useState(null);
    const swiperRef = useRef(null); // Ref to control Swiper instance

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useQuery({
        queryKey: ["singleProduct", id],
        queryFn: () => fetchData(id),
    });

    const product = data?.product;

    if (isLoading) return <div>{t.loading}</div>;

    if (error) {
        console.error(error);
        navigate("/error");
    }

    return (
        <>
            {selectedImage && (
                <ImageModal
                    images={product?.images}
                    imageUrl={selectedImage} //mainImage
                    onClose={handleCloseModal}
                    setMainImage={setSelectedImage}
                />
            )}
            <Tagline />
            <Navbar navClass="defaultscroll is-sticky tagline-height" />
            <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">
                            {product?.name?.[language]}
                        </h3>
                    </div>
                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-block">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/">{t.Onlybrands}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/shop">{t.store}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li
                                className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                aria-current="page"
                            >
                                {product?.name?.[language]}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6 items-center">
                        <div className="lg:col-span-5 relative">
                            <Swiper
                                ref={swiperRef} // Attach ref to Swiper instance
                                pagination={{ clickable: true }}
                                loop
                                modules={[Pagination, Autoplay]}
                                className="swiper-container"
                            >
                                {product?.images?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="w-full h-[400px]  lg:h-[730px] flex justify-center items-center overflow-hidden relative">
                                            <img
                                                src={
                                                    item.includes("http")
                                                        ? item
                                                        : `https://api.onlybrands.ge/storage/${item}`
                                                }
                                                className="w-full h-full object-cover rounded-lg cursor-pointer"
                                                alt="product"
                                            />
                                            {/* Icon positioned at the top-right corner */}
                                            <button
                                                onClick={() => handleImageClick(item)}
                                                className="hover:scale-110 duration-150  absolute top-2 right-2 bg-white border border-gray-300 rounded-full p-2 cursor-pointer shadow-md"
                                            >
                                                <IoMdResize className="text-gray-600" />
                                            </button>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {/* Custom Swiper navigation buttons */}
                            <button
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 flex items-center p-4 rounded-full border border-gray-400 dark:text-black hover:bg-gray-50/50"
                                onClick={() => swiperRef.current.swiper.slideNext()} // Next slide
                            >
                                <IoIosArrowBack className="rotate-180 text-3xl" />{" "}
                                {/* Increase size with text-3xl */}
                            </button>
                            <button
                                className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10 flex items-center p-4 rounded-full border border-gray-400 dark:text-black hover:bg-gray-50/50"
                                onClick={() => swiperRef.current.swiper.slidePrev()} // Previous slide
                            >
                                <IoIosArrowBack className="text-3xl" />{" "}
                                {/* Increase size with text-3xl */}
                            </button>
                        </div>

                        <div className="lg:col-span-7">
                            <ProductDetail product={product} />
                        </div>
                    </div>
                </div>
                <ArrivalItem data={data?.newArrivals} language={language} />
            </section>
            <Footer />
            <ScrollToTop />
        </>
    );
}
