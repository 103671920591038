import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import ScrollToTop from "../../components/scroll-to-top";
import Error from "../pages/special/error";
import Loading from "../../components/loading";
import ShopItemCard from "../../components/shop-item-card";
import axiosInstance from "../../utils/axiosInstance";
import { handleGetLanguage } from "../../utils/languageStorage";

const fetchFavorites = async () => {
    const response = await axiosInstance.get(`/products/favorites`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function Favorites(props) {
    const language = handleGetLanguage();
    const {
        data,
        isLoading: isFavoritesLoading,
        error,
    } = useQuery({
        queryKey: ["favoriteProducts"],
        queryFn: fetchFavorites,
    });

    const translations = {
        en: {
            favorites: "Favorites",
            Onlybrands: "Onlybrands",
            favoritesPage: "FAVORITES",
            failedToLoad: "Failed to load favorite products.",
            noData: "No favorite products found.",
        },
        ka: {
            favorites: "ფავორიტები",
            Onlybrands: "მხოლოდ ბრენდები",
            favoritesPage: "ფავორიტები",
            failedToLoad: "ფავორიტული პროდუქტების ჩატვირთვა ვერ მოხერხდა.",
            noData: "ფავორიტული პროდუქტები არ მოიძებნა.",
        },
    };

    const t = translations[language] || translations.en;

    if (isFavoritesLoading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navClass="defaultscroll is-sticky" />
            <main className="flex-grow">
                <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                    <div className="container relative">
                        <div className="grid grid-cols-1 mt-14">
                            <h3 className="text-3xl leading-normal font-semibold">{t.favorites}</h3>
                        </div>

                        <div className="relative mt-3">
                            <ul className="tracking-[0.5px] mb-0 inline-block">
                                <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                    <Link to="/">{t.Onlybrands}</Link>
                                </li>
                                <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                    <i className="mdi mdi-chevron-right"></i>
                                </li>
                                <li
                                    className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                    aria-current="page"
                                >
                                    {t.favoritesPage}
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                {error ? (
                    <section className="relative md:p-24 p-16">
                        <p className="text-red-500">{t.failedToLoad}</p>
                    </section>
                ) : (
                    <section className="relative md:p-24 p-16">
                        {data?.length === 0 ? (
                            <div className="text-center min-h-[200px] flex items-center justify-center">
                                {t.noData}
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-5">
                                {data?.map((item, index) => (
                                    <ShopItemCard key={index} item={item} />
                                ))}
                            </div>
                        )}
                    </section>
                )}
            </main>
            <Footer />
            <ScrollToTop />
        </div>
    );
}
