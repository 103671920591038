import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useCartStore from "../store/cartStore";
import { toast } from "sonner";
import useAuthMoadl from "../store/authWarningModal";
import useUserData from "../store/userStore";
import { handleGetLanguage } from "../utils/languageStorage";

export default function ProductDetail({ product }) {
    const [count, setCount] = useState(1);
    const [size, setSize] = useState("");
    const [colore, setColore] = useState("");

    const { addToCart } = useCartStore((state) => state);
    const { show } = useAuthMoadl((state) => state);
    const { user } = useUserData((state) => state);

    const navigate = useNavigate();
    const language = handleGetLanguage();

    const increments = () => {
        setCount(count + 1);
    };

    const decrements = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    const sizes = product.sizes;

    const colors = product.colors;

    const translations = {
        en: {
            shopNow: "Shop Now",
            addToCart: "Add to Cart",
            overview: "Overview",
            size: "Size",
            quantity: "Quantity",
            colors: "Colors",
            youHaveToAdd: "You have to add color ",
            addedToCart: "You have added product to cart",
            digitalMarketing: "Digital Marketing Solutions for Tomorrow",
            experiencedMarketing: "Our Talented & Experienced Marketing Agency",
            createSkin: "Create your own skin to match your brand",
        },
        ka: {
            shopNow: "ყიდვა",
            addToCart: "კალათაში დამატება",
            overview: "მიმოხილვა",
            size: "ზომა",
            quantity: "რაოდენობა",
            colors: "ფერები",
            youHaveToAdd: "ფერი  უნდა დაამატოთ",
            addedToCart: "პროდუქტი კალათაში დაემატა",
            digitalMarketing: "ციფრული მარკეტინგის გადაწყვეტილებები მომავლისთვის",
            experiencedMarketing: "ჩვენი გამოცდილი მარკეტინგის სააგენტო",
            createSkin: "შექმენით საკუთარი კანის ბრენდის შესაბამისი",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className="sticky top-20">
            <h5 className="text-2xl font-semibold">{product?.name?.[language]}</h5>
            <div className="mt-2">
                <span className="text-slate-400 font-semibold me-1">
                    {product?.discounted_price}GEL{" "}
                    <del className="text-red-600"> {product?.price}GEL</del>
                </span>
            </div>

            <div className="mt-4">
                <h5 className="text-lg font-semibold">{t.overview} :</h5>
                <p className="text-slate-400 mt-2">{product?.overview?.[language]}</p>

                <ul className="list-none text-slate-400 mt-4">
                    <li className="mb-1 flex ms-0">
                        <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>
                        {t.digitalMarketing}
                    </li>
                    <li className="mb-1 flex ms-0">
                        <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>
                        {t.experiencedMarketing}
                    </li>
                    <li className="mb-1 flex ms-0">
                        <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>
                        {t.createSkin}
                    </li>
                </ul>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">
                {/* <div className="flex items-center">
                    <h5 className="text-lg font-semibold me-2">{t.size}:</h5>
                    <div className="space-x-1">
                        {sizes &&
                            sizes.map((item, i) => (
                                <button
                                    key={i}
                                    className={`size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md  hover:bg-orange-500  hover:text-white ${
                                        size === item.id
                                            ? "bg-orange-500 text-white"
                                            : "bg-orange-500/5 text-orange-500"
                                    }`}
                                    onClick={() => {
                                        setSize(item?.id);
                                    }}
                                >
                                    {item?.name}
                                </button>
                            ))}
                    </div>
                </div> */}

                <div className="flex items-center">
                    <h5 className="text-lg font-semibold me-2">{t.quantity}:</h5>
                    <div className="qty-icons ms-3 space-x-0.5">
                        <button
                            onClick={() => decrements()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                        >
                            -
                        </button>
                        <input
                            min="0"
                            name="quantity"
                            value={count}
                            onChange={() => {}}
                            type="number"
                            className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                        />
                        <button
                            onClick={() => increments()}
                            className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                        >
                            +
                        </button>
                    </div>
                </div>

                <div className="flex items-center">
                    <h5 className="text-lg font-semibold me-2">{t.colors}:</h5>
                    <div className="space-x-2">
                        {colors &&
                            colors.map((item, i) => (
                                <button
                                    onClick={() => {
                                        setColore(item?.id);
                                    }}
                                    key={i}
                                    style={{ backgroundColor: item?.name }}
                                    className={`size-6 rounded-full ring-2  dark:ring-slate-800  inline-flex align-middle ${
                                        colore === item.id
                                            ? "ring-black dark:ring-white"
                                            : "ring-gray-200"
                                    }`}
                                    title={item?.name}
                                ></button>
                            ))}
                    </div>
                </div>
            </div>

            <div className="mt-4 space-x-1">
                <button
                    onClick={() => {
                        if (!user?.id || !user?.email) {
                            show();
                        } else {
                            if (!colore) {
                                toast.error(t.youHaveToAdd);
                            } else {
                                navigate(
                                    `/shop-checkout?product=${product?.id}&color=${colore}&size=${size}&qtn=${count}`
                                );
                            }
                        }
                    }}
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                >
                    {t.shopNow}
                </button>
                <button
                    onClick={() => {
                        if (!user?.id || !user?.email) {
                            show();
                            return;
                        }

                        if (!colore) {
                            toast.error(t.youHaveToAdd);
                            return;
                        }

                        addToCart({
                            id: product?.id,
                            name: product?.name?.[language],
                            images: product?.images[0],
                            qtn: count,
                            size,
                            color: colore,
                            price: product?.discounted_price || product?.price,
                        });

                        toast.success(t.addedToCart);
                        setCount(1);
                        setColore("");
                        setSize("");
                    }}
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2 active:opacity-50"
                >
                    {t.addToCart}
                </button>
            </div>
        </div>
    );
}
