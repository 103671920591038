import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from "react-icons/io";

const prefix = `https://api.onlybrands.ge/storage/`;

const ImageCarousel = ({ images, setMainImage, mainImage }) => {
    return (
        <div className="flex sm:flex-col gap-3 items-center sm:mr-20">
            {images.map((item, index) => (
                <button
                    key={index}
                    onClick={() => setMainImage(item)}
                    className={`p-1 ${mainImage === item ? "border-2 border-blue-500" : ""}`}
                >
                    <img
                        src={prefix + item}
                        alt={`Thumbnail ${index}`}
                        className="h-[75px] w-[75px] sm:h-[100px] sm:w-[100px] object-cover hover:scale-105 transition-transform duration-150"
                    />
                </button>
            ))}
        </div>
    );
};

const ImageModal = ({ imageUrl, onClose, images }) => {
    const [mainImage, setMainImage] = useState(imageUrl);
    const currentIndex = images.indexOf(mainImage);

    const prevImage = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        setMainImage(images[prevIndex]);
    };

    const nextImage = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setMainImage(images[nextIndex]);
    };

    if (!imageUrl) return null;

    return (
        <div className="fixed inset-0 z-50 flex flex-col sm:flex-row items-center justify-center bg-gray-900 bg-opacity-80 backdrop-blur-md p-4 md:p-8">
            <div className="flex flex-col gap-5 sm:gap-0">
                <ImageCarousel images={images} setMainImage={setMainImage} mainImage={mainImage} />
            </div>

            <div className="relative flex items-center mx-2 sm:mx-5 mt-4 sm:mt-0">
                <button
                    onClick={prevImage}
                    className="p-2 bg-white rounded-full absolute left-[-20px] sm:left-[-80px] text-black hover:scale-110 duration-150"
                >
                    <IoIosArrowBack className="h-4 w-4 sm:h-10 sm:w-10" />
                </button>

                <img
                    src={prefix + mainImage}
                    alt="ProductImage"
                    className="max-w-[90vw] max-h-[70vh] sm:max-h-[85vh] object-contain"
                />

                <button
                    onClick={nextImage}
                    className="p-2 bg-white rounded-full absolute right-[-20px] sm:right-[-80px] text-black hover:scale-110 duration-150"
                >
                    <IoIosArrowForward className="h-4 w-4 sm:h-10 sm:w-10" />
                </button>

                <button
                    onClick={onClose}
                    className="p-2 border border-black bg-white rounded-full absolute top-2 right-2 text-black hover:scale-110 duration-150"
                >
                    <IoMdClose className="h-5 w-5" />
                </button>
            </div>
        </div>
    );
};

export default ImageModal;
