import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import ScrollToTop from "../../components/scroll-to-top";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../utils/axiosInstance";
import useCartStore from "../../store/cartStore";
import { handleGetLanguage } from "../../utils/languageStorage";
import useUserData from "../../store/userStore";

const fetchSingleProduct = async (id) => {
    const response = await axiosInstance.get(`/products/${id}`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function ShopCheckOut() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { user } = useUserData((state) => state);
    const { cart } = useCartStore((state) => state);

    const productQuery = queryParams.get("product");
    const colorQuery = queryParams.get("color");
    const sizeQuery = queryParams.get("size");
    const qtnQuery = queryParams.get("qtn");

    const { data } = useQuery({
        queryKey: ["singleProductCheckout", productQuery],
        queryFn: () => fetchSingleProduct(productQuery),
    });

    const color = data?.product?.colors.find((item) => item.id === +colorQuery);
    const size = data?.product?.sizes.find((item) => item.id === +sizeQuery);

    const [city, setCity] = useState("Tbilisi");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState(user?.phone_number && user?.phone_number);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        // Check if any of the required fields are empty
        if (city && firstName && lastName && address && phone) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [city, firstName, lastName, address, phone]);

    const totalPrice = () => {
        let totalAmount = 0;
        for (const item of cart) {
            let itemsPrice = +item.price * +item.qtn;
            totalAmount = totalAmount + itemsPrice;
        }
        return totalAmount;
    };

    console.log(user);

    const language = handleGetLanguage();
    const translations = {
        en: {
            fashion: "Fashion",
            cartzio: "Cartzio",
            checkout: "Checkout",
            billingAddress: "Billing address",
            city: "City",
            firstName: "First Name",
            lastName: "Last Name",
            username: "Username",
            yourEmail: "Your Email",
            address: "Address",
            sameAddress: "Shipping address is the same as my billing address",
            saveNextTime: "Save this information for next time",
            payment: "Payment",
            creditCard: "Credit card",
            accountHolderName: "Account Holder Name",
            creditCardNumber: "Credit card number",
            expiration: "Expiration",
            cvv: "CVV",
            continueCheckout: "Continue to checkout",
            yourCart: "Your Cart",
            promoCode: "Referral code",
            total: "Total (USD)",
            redeem: "Redeem",
            color: "color",
            size: "size",
            count: "count",
            phone: "Phone",
        },
        ka: {
            fashion: "მოდა",
            cartzio: "კარტციო",
            checkout: "გადახდა",
            billingAddress: "ბილინგის მისამართი",
            city: "ქალაქი",
            firstName: "სახელი",
            lastName: "გვარი",
            username: "მომხმარებლის სახელი",
            yourEmail: "თქვენი ელ.ფოსტა",
            address: "მისამართი",
            sameAddress: "მიწოდების მისამართი იგივეა, რაც ბილინგის მისამართი",
            saveNextTime: "შეინახეთ ეს ინფორმაცია შემდეგ ჯერზე",
            payment: "გადახდა",
            creditCard: "საკრედიტო ბარათი",
            accountHolderName: "ანგარიშის მფლობელის სახელი",
            creditCardNumber: "საკრედიტო ბარათის ნომერი",
            expiration: "ვადის გასვლა",
            cvv: "CVV",
            continueCheckout: "გაგრძელება გადახდაზე",
            yourCart: "თქვენი კალათა",
            promoCode: "რფერალური კოდი",
            total: "სულ (USD)",
            redeem: "გამოყენება",
            color: "ფერი",
            size: "ზომა",
            count: "რაოდენობა",
            phone: "ტელეფონი",
        },
    };

    const t = translations[language] || translations.en;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const values = Object.fromEntries(formData.entries());
        console.log(values);
    };

    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" />
            <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">{t.fashion}</h3>
                    </div>

                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-block">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/">{t.cartzio}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li
                                className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                aria-current="page"
                            >
                                {t.checkout}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <form onSubmit={handleFormSubmit}>
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
                            <div className="lg:col-span-8">
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                                    <h3 className="text-xl leading-normal font-semibold">
                                        {t.billingAddress}
                                    </h3>

                                    <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
                                        <div className="lg:col-span-12">
                                            <label className="form-label font-semibold">
                                                {t.city}: <span className="text-red-600">*</span>
                                            </label>
                                            <select
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                name="city"
                                                id="city"
                                                required
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            >
                                                <option value="Tbilisi">Tbilisi / თბილისი</option>
                                            </select>
                                        </div>
                                        <div className="lg:col-span-6">
                                            <label className="form-label font-semibold">
                                                {t.firstName}:{" "}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                placeholder={t.firstName}
                                                id="firstname"
                                                name="firstname"
                                                required
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>

                                        <div className="lg:col-span-6">
                                            <label className="form-label font-semibold">
                                                {t.lastName}:{" "}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                placeholder={t.lastName}
                                                id="lastname"
                                                name="lastname"
                                                required
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label className="form-label font-semibold">
                                                {t.address}: <span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                placeholder={t.address}
                                                id="address"
                                                name="address"
                                                required
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                        <div className="lg:col-span-12">
                                            <label className="form-label font-semibold">
                                                {t.phone}
                                                <span className="text-red-600">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                placeholder={t.phone}
                                                id="phone"
                                                name="phone"
                                                required
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="lg:col-span-12">
                                            <label className="form-label font-semibold">
                                                {t.promoCode}
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                                                placeholder={t.promoCode}
                                                id="promoCode"
                                                name="promoCode"
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="submit"
                                            className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full disabled:opacity-70"
                                            disabled={isButtonDisabled}
                                        >
                                            {t.continueCheckout}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="lg:col-span-4">
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                                    <div className="flex justify-between items-center">
                                        <h5 className="text-lg font-semibold">{t.yourCart}</h5>

                                        <Link
                                            to="#"
                                            className="bg-orange-500 flex justify-center items-center text-white text-[10px] font-bold px-2.5 py-0.5 rounded-full h-5"
                                        >
                                            {cart.length !== 0 ? cart.length : 1}
                                        </Link>
                                    </div>

                                    {!colorQuery || !qtnQuery ? (
                                        <div className="mt-4 rounded-md shadow dark:shadow-gray-800">
                                            {cart.map((item) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className="p-3 flex justify-between items-center"
                                                    >
                                                        <div>
                                                            <h5 className="font-semibold">
                                                                {item?.name}
                                                            </h5>
                                                            <div className="text-xs text-slate-400 flex gap-4">
                                                                <p>
                                                                    {t.color} : {item.color}
                                                                </p>
                                                                <p>
                                                                    {t.size} : {item.size}
                                                                </p>
                                                                <p>
                                                                    {t.count} : {item.qtn}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <p className="text-slate-400 font-semibold">
                                                            $ {item.qtn * item.price}
                                                        </p>
                                                    </div>
                                                );
                                            })}

                                            <div className="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800 text-green-600">
                                                <div>
                                                    <h5 className="font-semibold">{t.promoCode}</h5>
                                                    <p className="text-sm text-green-600">
                                                        EXAMPLECODE
                                                    </p>
                                                </div>

                                                <p className="text-red-600 font-semibold">-$10</p>
                                            </div>
                                            <div className="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                                                <div>
                                                    <h5 className="font-semibold">{t.total}</h5>
                                                </div>

                                                <p className="font-semibold">{totalPrice()}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mt-4 px-2 rounded-md shadow dark:shadow-gray-800 ">
                                            <div className="p-3 flex justify-between items-center">
                                                <div>
                                                    <h5 className="font-semibold">
                                                        {data?.product?.name?.[language]}
                                                    </h5>
                                                    <div className="text-xs text-slate-400 flex gap-4">
                                                        <p>
                                                            {t.color} : {color?.name}
                                                        </p>
                                                        {/* <p>
                                                            {t.size} : {size?.name}
                                                        </p> */}
                                                        <p>
                                                            {t.count} : {qtnQuery}
                                                        </p>
                                                    </div>
                                                </div>

                                                <p className="text-slate-400 font-semibold">
                                                    ${" "}
                                                    {qtnQuery * data?.product?.discounted_price
                                                        ? data?.product?.discounted_price
                                                        : data?.product?.price}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <Footer />
            <ScrollToTop />
        </>
    );
}
