import React, { useEffect, useRef, useState } from "react";
import useAuthMoadl from "../store/authWarningModal";
import Login from "../pages/pages/auth/login";
import Signup from "../pages/pages/auth/signup";

const Modal = () => {
  const { isShown, type, hide } = useAuthMoadl((state) => state);

  const [toggleAuth, setTogglAuth] = useState(type || "signin");

  const containerRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        hide();
      }
    }

    isShown && document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, isShown, hide]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/70 ">
      <div
        className="relative  w-full max-w-2xl p-6 mx-4 bg-white dark:bg-slate-900 rounded-lg shadow-lg md:mx-auto flex flex-col gap-8"
        ref={containerRef}
      >
        {toggleAuth === "signin" ? (
          <Login setTogglAuth={setTogglAuth} />
        ) : (
          <Signup setTogglAuth={setTogglAuth} />
        )}
      </div>
    </div>
  );
};

export default Modal;
