import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import Filter from "../../../components/filter";

import { FiChevronLeft, FiChevronRight } from "../../../assets/icons/vander";
import ScrollToTop from "../../../components/scroll-to-top";
import ShopItemCard from "../../../components/shop-item-card";
import axiosInstance from "../../../utils/axiosInstance";
import { handleGetLanguage } from "../../../utils/languageStorage";

export default function ShopGridLeftSidebar() {
    const language = handleGetLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const genderQuery = queryParams.get("gender");
    const pageQuery = queryParams.get("page");
    const searchQuery = queryParams.get("search");
    const typeQuery = queryParams.get("type");
    const colorQuery = queryParams.get("colors");
    const brandQuery = queryParams.get("brands");
    const sizeQuery = queryParams.get("sizes");
    const orderyQuery = queryParams.get("sort_order");
    const categorieQuery = queryParams.get("categorie");
    const priceQuery = queryParams.get("price");

    const updateQuery = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const removeQuery = (key) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(key);
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = () => {
            setLoading(true);

            const params = new URLSearchParams();
            if (pageQuery) params.append("page", pageQuery);
            if (genderQuery) params.append("gender", genderQuery);
            if (typeQuery) params.append("type", typeQuery);
            if (colorQuery) params.append("colors", colorQuery);
            if (brandQuery) params.append("brands", brandQuery);
            if (sizeQuery) params.append("sizes", sizeQuery);
            if (searchQuery) params.append("search", searchQuery);
            if (orderyQuery) params.append("sort_order", orderyQuery);
            if (categorieQuery) params.append("categorie", categorieQuery);
            if (priceQuery) params.append("price", priceQuery);

            const url = `/products?${params.toString()}`;

            axiosInstance
                .get(url)
                .then((res) => {
                    setProducts(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };

        fetchProducts();
    }, [
        searchQuery,
        typeQuery,
        colorQuery,
        brandQuery,
        sizeQuery,
        orderyQuery,
        pageQuery,
        genderQuery,
        categorieQuery,
        priceQuery,
    ]);

    const translations = {
        en: {
            fashion: "Fashion",
            showingItems: `Showing ${products?.from}-${products?.to} of ${products?.total} items`,
            sortPrice: "Sort price:",
            default: "default",
            byLowHigh: "By Low-High",
            byHighLow: "By High-Low",
            Onlybrands: "Onlybrands",
            shop: "Shop",
        },
        ka: {
            fashion: "მოდა",
            showingItems: "ნაჩვენებია 1-16 40 პროდუქტიდან",
            sortPrice: "ფასის სორტირება:",
            default: "ნაგულისხმები",
            byLowHigh: "დაბალი-მაღალი",
            byHighLow: "მაღალი-დაბალი",
            Onlybrands: "მხოლოდ ბრენდები",
            shop: "მაღაზია",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className="flex flex-col min-h-screen">
            {/* Navbar */}
            <Navbar navClass="defaultscroll is-sticky" categoriesQuery={categorieQuery} />

            {/* Main Content */}
            <main className="flex-grow">
                <section className="hidden  relative md:table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                    <div className="container relative"></div>
                </section>
                <section className="md:p-24 py-16">
                    <div className="w-full px-6">
                        {/* Use flex layout for medium screens and above */}
                        <div className="relative md:flex gap-6">
                            {/* Filter component with sticky behavior on larger screens */}
                            <div className="w-full md:w-1/4">
                                {/* Apply sticky positioning only on medium screens and above */}
                                <div className="mb-5 md:mb-0 md:sticky md:top-20">
                                    {/* Adjusted top for navbar space */}
                                    <Filter
                                        searchQuery={searchQuery}
                                        typeQuery={typeQuery}
                                        genderQuery={genderQuery}
                                        colorQuery={colorQuery}
                                        brandQuery={brandQuery}
                                        sizeQuery={sizeQuery}
                                        removeQuery={removeQuery}
                                        updateQuery={updateQuery}
                                    />
                                </div>
                            </div>

                            {/* Products grid */}
                            <div className="w-full md:w-3/4">
                                {/* Sorting Dropdown */}
                                <div className="md:flex items-center mb-6">
                                    <label className="font-semibold md:me-2">{t.sortPrice}</label>
                                    <select
                                        onChange={(e) => {
                                            if (e.target.value !== "none") {
                                                navigate(updateQuery("sort_order", e.target.value));
                                            } else {
                                                navigate(removeQuery("sort_order"));
                                            }
                                        }}
                                        className="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-2 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                    >
                                        <option value="none">{t.default}</option>
                                        <option value="asc">{t.byLowHigh}</option>
                                        <option value="desc">{t.byHighLow}</option>
                                    </select>
                                </div>

                                {/* Products Grid */}
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 md:gap-32">
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : products?.data && products.data.length > 0 ? (
                                        products.data.map((item, index) => (
                                            <ShopItemCard item={item} key={index} />
                                        ))
                                    ) : (
                                        <div className="col-span-full text-center py-10">
                                            <p className="text-gray-500 dark:text-gray-400">
                                                {language === "ka"
                                                    ? "პროდუქტები არ მოიძებნა"
                                                    : "No products found."}
                                            </p>
                                            <Link
                                                to="/shop"
                                                className="mt-4 inline-block text-orange-600 hover:text-orange-500 transition-colors"
                                            >
                                                {language === "ka"
                                                    ? "უკან დაბრუნება"
                                                    : "Back to Shop"}
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                                    <div className="md:col-span-12 text-center">
                                        <nav aria-label="Page navigation example">
                                            <ul className="inline-flex items-center -space-x-px">
                                                {products?.links &&
                                                    products.links.map((linkItem, index) => {
                                                        let label = "";

                                                        switch (index) {
                                                            case 0:
                                                                label = (
                                                                    <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1" />
                                                                );
                                                                break;
                                                            case products.links.length - 1:
                                                                label = (
                                                                    <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1" />
                                                                );
                                                                break;
                                                            default:
                                                                label = linkItem.label;
                                                        }

                                                        return (
                                                            <li key={index}>
                                                                <button
                                                                    onClick={() => {
                                                                        if (linkItem.url) {
                                                                            const pageNumber =
                                                                                new URL(
                                                                                    linkItem.url
                                                                                ).searchParams.get(
                                                                                    "page"
                                                                                ) || linkItem.label;
                                                                            navigate(
                                                                                updateQuery(
                                                                                    "page",
                                                                                    pageNumber
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                    disabled={
                                                                        !linkItem.url ||
                                                                        linkItem.active
                                                                    }
                                                                    className={`size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
                                                                        (!linkItem.url ||
                                                                            linkItem.active) &&
                                                                        "cursor-not-allowed opacity-50"
                                                                    }`}
                                                                >
                                                                    {label}
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <Footer />

            {/* Additional Components */}
            <ScrollToTop />
        </div>
    );
}
