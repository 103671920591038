import { Link, useNavigate } from "react-router-dom";

import { FiAirplay, FiEdit, FiSettings, FiLogOut } from "react-icons/fi";
import { handleDeleteAuthCookie } from "../utils/token";
import useUserData from "../store/userStore";
import { toast } from "sonner";
import axiosInstance from "../utils/axiosInstance";
import { handleGetLanguage } from "../utils/languageStorage";

export default function Usertab({ userName, userEmail }) {
    let current = window.location.pathname;
    const language = handleGetLanguage();

    const { removeUserData } = useUserData((state) => state);

    const navigate = useNavigate();

    const logOutHandler = async () => {
        await axiosInstance
            .post("/logout")
            .then((res) => {
                console.log(res);
                handleDeleteAuthCookie();
                removeUserData();
                toast.success("User logged out");
            })
            .catch((err) => console.log(err));
    };

    const translations = {
        en: {
            orders: "Orders",
            billingInfo: "Billing Info",
            settings: "Settings",
            signOut: "Sign Out",
        },
        ka: {
            orders: "შეკვეთები",
            billingInfo: "ბილინგის ინფორმაცია",
            settings: "პარამეტრები",
            signOut: "გასვლა",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className="lg:w-1/4 md:w-1/3 md:px-3">
            <div className="relative md:-mt-48 -mt-32">
                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                    <div className="profile-pic text-center mb-5">
                        <div className="relative h-28 w-28 mx-auto">
                            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 rounded-full">
                                <span className="text-2xl font-bold text-white">
                                    {userName?.slice(0, 2).toUpperCase()}
                                </span>
                            </div>
                        </div>

                        <div className="mt-4">
                            <h5 className="text-lg font-semibold">{userName}</h5>
                            <p className="text-slate-400">{userEmail}</p>
                        </div>
                    </div>

                    <div className="border-t border-gray-100 dark:border-gray-700">
                        <ul className="list-none sidebar-nav mb-0 pb-0" id="navmenu-nav">
                            <li
                                className={`navbar-item account-menu ms-0 ${
                                    current === "/user-account" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="/user-orders"
                                    className="navbar-link text-slate-400 flex items-center py-2 rounded"
                                >
                                    <span className="me-2 mb-0">
                                        <FiAirplay className="size-4"></FiAirplay>
                                    </span>
                                    <h6 className="mb-0 font-medium">{t.orders}</h6>
                                </Link>
                            </li>
                            {/* 
                            <li
                                className={`navbar-item account-menu ms-0 ${
                                    current === "/user-billing" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="/user-billing"
                                    className="navbar-link text-slate-400 flex items-center py-2 rounded"
                                >
                                    <span className="me-2 mb-0">
                                        <FiEdit className="size-4"></FiEdit>
                                    </span>
                                    <h6 className="mb-0 font-medium">{t.billingInfo}</h6>
                                </Link>
                            </li> */}

                            <li
                                className={`navbar-item account-menu ms-0 ${
                                    current === "/user-setting" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to="/user-setting"
                                    className="navbar-link text-slate-400 flex items-center py-2 rounded"
                                >
                                    <span className="me-2 mb-0">
                                        <FiSettings className="size-4"></FiSettings>
                                    </span>
                                    <h6 className="mb-0 font-medium">{t.settings}</h6>
                                </Link>
                            </li>

                            <li
                                className={`navbar-item account-menu ms-0 ${
                                    current === "/lock-screen" ? "active" : ""
                                }`}
                            >
                                <button
                                    onClick={() => {
                                        logOutHandler();
                                        navigate("/");
                                    }}
                                    className="navbar-link text-slate-400 flex items-center py-2 rounded"
                                >
                                    <span className="me-2 mb-0">
                                        <FiLogOut className="size-4"></FiLogOut>
                                    </span>
                                    <span className="mb-0 font-medium">{t.signOut}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
