import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../utils/axiosInstance";
import Loading from "../../../components/loading";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import Switcher from "../../../components/switcher";
import ScrollToTop from "../../../components/scroll-to-top";
import { handleGetLanguage } from "../../../utils/languageStorage";

const fetchFavorites = async () => {
    const response = await axiosInstance.get(`/payment-histories`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function Favorites(props) {
    const language = handleGetLanguage();
    const {
        data,
        isLoading: isFavoritesLoading,
        error,
    } = useQuery({
        queryKey: ["favoriteProducts"],
        queryFn: fetchFavorites,
    });

    const translations = {
        en: {
            favorites: "Orders",
            Onlybrands: "Onlybrands",
            favoritesPage: "ORDERS",
            failedToLoad: "Failed to load Ordered products.",
            noData: "No Orders found.",
        },
        ka: {
            favorites: "შეკვეთილები",
            Onlybrands: "მხოლოდ ბრენდები",
            favoritesPage: "შეკვეთილები",
            failedToLoad: "შეკვეთილი პროდუქტების ჩატვირთვა ვერ მოხერხდა.",
            noData: "შეკვეთილი პროდუქტები არ მოიძებნა.",
        },
    };

    const t = translations[language] || translations.en;

    const formatDate = (dateString) => {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        return new Intl.DateTimeFormat(language, options).format(new Date(dateString));
    };

    if (isFavoritesLoading) {
        return <Loading />;
    }

    return (
        <>
            <Navbar navClass="defaultscroll is-sticky" />
            <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">{t.favorites}</h3>
                    </div>

                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-block">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                <Link to="/">{t.Onlybrands}</Link>
                            </li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                <i className="mdi mdi-chevron-right"></i>
                            </li>
                            <li
                                className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                aria-current="page"
                            >
                                {t.favoritesPage}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {error ? (
                <section className="relative md:p-24 p-16">
                    <p className="text-red-500">{t.failedToLoad}</p>
                </section>
            ) : (
                <section className="relative md:p-24 p-16">
                    {data.map((item) => (
                        <div className="w-full bg-gray-50 py-5 px-4 lg:px-10 rounded-lg flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 lg:gap-0">
                            <div className="flex flex-col lg:flex-row gap-4">
                                {item.products.map((product) => (
                                    <span className="flex items-center" key={product.id}>
                                        <img
                                            src={product?.images[0]}
                                            className="rounded shadow dark:shadow-gray-800 w-16 h-16 lg:w-12 lg:h-12 object-cover"
                                            alt=""
                                        />
                                        <span className="ms-3">
                                            <span className="block font-semibold text-sm lg:text-base">
                                                {product?.name.en}
                                            </span>
                                        </span>
                                    </span>
                                ))}
                            </div>
                            <p className="text-base lg:text-lg">{item.total_cost} GEL</p>
                            <p className="text-sm lg:text-base">{formatDate(item.created_at)}</p>
                        </div>
                    ))}
                </section>
            )}

            <Footer />
            <ScrollToTop />
        </>
    );
}
