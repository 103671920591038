import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import Counter from "../../components/counter";

import ScrollToTop from "../../components/scroll-to-top";
import useCartStore from "../../store/cartStore";
import { handleGetLanguage } from "../../utils/languageStorage";

export default function Shopcart(props) {
    const language = handleGetLanguage();
    const { cart, removeFromCart } = useCartStore((state) => state);

    const totalPrice = () => {
        let totalAmount = 0;
        for (const item of cart) {
            let itemsPrice = +item.price * +item.qtn;
            totalAmount = totalAmount + itemsPrice;
        }
        return totalAmount;
    };

    const translations = {
        en: {
            fashion: "Cart",
            Onlybrands: "Onlybrands",
            shopcart: "Shopcart",
            product: "Product",
            price: "Price",
            qty: "Qty",
            total: "Total($)",
            checkOut: "Check Out",
            addToCart: "Add to Cart",
            subtotal: "Subtotal",
            taxes: "Taxes",
            totalAmount: "Total",
            noData: "Your cart is empty.",
        },
        ka: {
            fashion: "კალათა",
            Onlybrands: "მხოლოდ ბრენდები",
            shopcart: "საყიდლების კალათა",
            product: "პროდუქტი",
            price: "ფასი",
            qty: "რაოდენობა",
            total: "სულ($)",
            checkOut: "გადახდა",
            addToCart: "კალათაში დამატება",
            subtotal: "სულ",
            taxes: "გადასახადები",
            totalAmount: "სულ თანხა",
            noData: "თქვენი კალათა ცარიელია.",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navClass="defaultscroll is-sticky" />
            <main className="flex-grow">
                <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                    <div className="container relative">
                        <div className="grid grid-cols-1 mt-14">
                            <h3 className="text-3xl leading-normal font-semibold">{t.fashion}</h3>
                        </div>

                        <div className="relative mt-3">
                            <ul className="tracking-[0.5px] mb-0 inline-block">
                                <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                                    <Link to="/">{t.Onlybrands}</Link>
                                </li>
                                <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                    <i className="mdi mdi-chevron-right"></i>
                                </li>
                                <li
                                    className="inline-block uppercase text-[13px] font-bold text-orange-500"
                                    aria-current="page"
                                >
                                    {t.shopcart}
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="relative md:py-24 py-16">
                    <div className="container relative">
                        {cart.length === 0 ? (
                            <div className="text-center min-h-[200px] flex items-center justify-center">
                                {t.noData}
                            </div>
                        ) : (
                            <div className="grid lg:grid-cols-1">
                                <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                                    <table className="w-full text-start">
                                        <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                                            <tr>
                                                <th scope="col" className="p-4 w-4"></th>
                                                <th
                                                    scope="col"
                                                    className="text-start p-4 min-w-[220px]"
                                                >
                                                    {t.product}
                                                </th>
                                                <th scope="col" className="p-4 w-24 min-w-[100px]">
                                                    {t.price}
                                                </th>
                                                <th scope="col" className="p-4 w-56 min-w-[220px]">
                                                    {t.qty}
                                                </th>
                                                <th scope="col" className="p-4 w-24 min-w-[100px]">
                                                    {t.total}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cart.map((item, index) => {
                                                console.log(item);
                                                return (
                                                    <tr
                                                        className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-800"
                                                        key={index}
                                                    >
                                                        <td className="p-4">
                                                            <button
                                                                onClick={() => removeFromCart(item)}
                                                            >
                                                                <i className="mdi mdi-window-close text-red-600"></i>
                                                            </button>
                                                        </td>
                                                        <td className="p-4">
                                                            <span className="flex items-center">
                                                                <img
                                                                    src={`https://api.onlybrands.ge/storage/${item?.images}`}
                                                                    className="rounded shadow dark:shadow-gray-800 w-12"
                                                                    alt=""
                                                                />
                                                                <span className="ms-3">
                                                                    <span className="block font-semibold">
                                                                        {item?.name}
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td className="p-4 text-center">
                                                            $ {item?.price}
                                                        </td>
                                                        <td className="p-4 text-center">
                                                            <Counter item={item} total="" />
                                                        </td>
                                                        <td className="p-4 text-end">
                                                            $ {+item?.price * +item?.qtn}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                                    <div className="lg:col-span-9 md:order-1 order-3">
                                        <div className="space-x-1">
                                            <Link
                                                to="/shop-checkout"
                                                className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                                            >
                                                {t.checkOut}
                                            </Link>
                                            <Link
                                                to="/shop"
                                                className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2"
                                            >
                                                {t.addToCart}
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-3 md:order-2 order-1">
                                        <ul className="list-none shadow dark:shadow-gray-800 rounded-md">
                                            <li className="flex justify-between p-4">
                                                <span className="font-semibold text-lg">
                                                    {t.subtotal} :
                                                </span>
                                                <span className="text-slate-400">
                                                    $ {totalPrice()}
                                                </span>
                                            </li>
                                            <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                                                <span className="font-semibold text-lg">
                                                    {t.taxes} :
                                                </span>
                                                <span className="text-slate-400">$ 150</span>
                                            </li>
                                            <li className="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                                                <span className="font-semibold text-lg">
                                                    {t.totalAmount} :
                                                </span>
                                                <span className="font-semibold">
                                                    $ {totalPrice() + 150}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>
            <Footer />
            <ScrollToTop />
        </div>
    );
}
