import React from "react";
import { Link } from "react-router-dom";

import Tagline from "../../components/tagline";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import ScrollToTop from "../../components/scroll-to-top";

import image1 from "../../assets/images/categories/ladies-ware.jpg";
import image2 from "../../assets/images/categories/kids-ware.jpg";
import image3 from "../../assets/images/categories/mens-ware.jpg";

import ShopItemCard from "../../components/shop-item-card";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { handleGetLanguage } from "../../utils/languageStorage";
import BrandSlider from "../../components/brand-slider";
// import Slogan from "../../components/Slogan";

const fetchData = async () => {
  const response = await axiosInstance.get(`/products/dashboard`);
  if (response.status !== 200) {
    throw new Error("Something went wrong");
  }
  return response.data;
};

export default function IndexThree() {
  const { data, isLoading } = useQuery({
    queryKey: ["productsDashboard"],
    queryFn: () => fetchData(),
  });

  const language = handleGetLanguage();

  const translations = {
    en: {
      ladiesWear: "Ladies Wear",
      kidsWear: "Kids Wear",
      gentsWear: "Gents Wear",
      trendingItems: "Trending Items",
      shopLatest: "Shop the latest products from the most popular items",
      seeMore: "See More Items",
      endOfSeason: "End of Season Clearance Sale upto 30%",
      upgradeStyle:
        "Upgrade your style with our curated sets. Choose confidence, embrace your unique look.",
      shopNow: "Shop Now",
      bestSellerItems: "Best Seller Items",
      popularCollections: "Shop the latest products from the most popular collections",
    },
    ka: {
      ladiesWear: "ქალბატონებისთვის",
      kidsWear: "ბავშვებისთვის",
      gentsWear: "მამაკაცებისთვის",
      trendingItems: "მოდური ნივთები",
      shopLatest: "იყიდეთ უახლესი პროდუქტები ყველაზე პოპულარული ნივთებიდან",
      seeMore: "იხილეთ მეტი ნივთები",
      endOfSeason: "სეზონის ბოლოს გაყიდვა 30% -მდე",
      upgradeStyle:
        "განაახლეთ თქვენი სტილი ჩვენი შერჩეული ნაკრებებით. აირჩიეთ თავდაჯერებულობა, მიაწოდეთ თქვენი უნიკალური სტილი.",
      shopNow: "იყიდეთ ახლა",
      bestSellerItems: "ყველაზე გაყიდვადი ნივთები",
      popularCollections: "იყიდეთ უახლესი პროდუქტები ყველაზე პოპულარული კოლექციებიდან",
    },
  };

  const t = translations[language] || translations.en;

  return (
    <>
      <Tagline />
      <Navbar navClass="defaultscroll is-sticky tagline-height" />

      <section className="relative mt-[70px] md:mt-0 md:pt-32">
        {/* <Slogan /> */}
        <BrandSlider />
        <div className="container-fluid relative">
          <div className="grid md:grid-cols-3">
            <div className="relative overflow-hidden group">
              <Link to="/shop?gender=1" className="text-center">
                <img src={image1} className="group-hover:scale-110 duration-500" alt="" />
                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                  {t.ladiesWear}
                </span>
              </Link>
            </div>

            <div className="relative overflow-hidden group">
              <Link to="/shop?gender=3" className="text-center">
                <img src={image2} className="group-hover:scale-110 duration-500" alt="" />
                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                  {t.kidsWear}
                </span>
              </Link>
            </div>

            <div className="relative overflow-hidden group">
              <Link to="/shop?gender=2" className="text-center">
                <img src={image3} className="group-hover:scale-110 duration-500" alt="" />
                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                  {t.gentsWear}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid items-end md:grid-cols-2 mb-6">
            <div className="md:text-start text-center">
              <h5 className="font-semibold text-3xl leading-normal mb-4">{t.trendingItems}</h5>
              <p className="text-slate-400 max-w-xl">{t.shopLatest}</p>
            </div>

            <div className="md:text-end hidden md:block">
              <Link to="/shop?type=is_trending" className="text-slate-400 hover:text-orange-500">
                {t.seeMore} <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            {isLoading ? (
              <div>Loading ...</div>
            ) : (
              data?.trendingItems?.map((item, index) => {
                return <ShopItemCard key={index} item={item} />;
              })
            )}
          </div>
          <div className="grid grid-cols-1 mt-6">
            <div className="text-center md:hidden block">
              <Link to="/shop?type=is_best_seller" className="text-slate-400 hover:text-orange-500">
                {t.seeMore} <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-28 w-full table relative bg-[url('../../assets/images/hero/bg6.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <h3 className="font-semibold text-3xl leading-normal mb-4 text-white">
              {t.endOfSeason} <br /> {t.upgradeStyle}
            </h3>

            <p className="text-white/80 max-w-xl mx-auto">{t.upgradeStyle}</p>

            <div className="mt-6">
              <Link
                to="/shop"
                className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-white text-orange-500 rounded-md"
              >
                <i className="mdi mdi-cart-outline"></i> {t.shopNow}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center text-center mb-6">
            <h5 className="font-semibold text-3xl leading-normal mb-4">{t.bestSellerItems}</h5>
            <p className="text-slate-400 max-w-xl mx-auto">{t.popularCollections}</p>
          </div>
          <div className="md:text-end hidden md:block">
            <Link to="/shop?type=is_best_seller" className="text-slate-400 hover:text-orange-500">
              {t.seeMore} <i className="mdi mdi-arrow-right"></i>
            </Link>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
            {isLoading ? (
              <div>Loading ...</div>
            ) : (
              data?.bestSeller?.map((item, index) => {
                return <ShopItemCard key={index} item={item} />;
              })
            )}
          </div>
        </div>

        {/* <Client /> */}
      </section>

      <Footer />
      {/* <Switcher /> */}
      <ScrollToTop />
    </>
  );
}
